<template>
  <b-nav-item-dropdown
    v-if="files.length > 0 && instructives.length > 0"
    right
    class="profileDd"
  >
    <template slot="button-content">
      <em><b-icon icon="question-circle"></b-icon></em>
    </template>
    <b-dropdown-item
      v-for="instructive in instructives"
      :key="instructive.id"
      :href="
        files.find((x) => x.id == instructive.file)
          ? files.find((x) => x.id == instructive.file).file
          : ''
      "
      target="_blank"
    >
      <b-icon-book class="mr-2"></b-icon-book>{{ instructive.name }}
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      isLoged: "isLoged",
      files: names.FILES,
      instructives: names.INSTRUCTIVES,
    }),
  },
  methods: {},
  watch: {},
  created() {
    if (this.isLoged) {
      this.$store.dispatch(names.FETCH_INSTRUCTIVES).then((response) => {
        response.forEach((instructive) => {
          this.$store.dispatch(names.FETCH_FILE, instructive.file);
        });
      });
    }
  },
};
</script>

<style>
</style>